import cookie from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import ShopifyClient from 'shopify-buy';
import { Checkout } from 'shopify-storefront-api-typings';

const SHOPIFY_CHECKOUT_STORAGE_KEY = 'shopify_checkout_id';

// @ts-ignore
const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN || '',
  domain: process.env.GATSBY_SHOPIFY_STORE || '',
});

interface InitialStore {
  shopifyClient: ShopifyClient;
  isAdding: boolean;
  cartIsOpen: boolean;
  navIsOpen: boolean;
  page: undefined;
  orders: any[];
  customerEmail: string | undefined;
  customerName: string | undefined;
  customerToken: string | undefined;
  checkout: Checkout;
  lang: string | undefined;
  collectionMenus: any[];
  collectionGrid: 's' | 'm' | 'l';
  collectionGridSize: 's' | 'm' | 'l';
  setCollectionGridSize: (size: string) => void;
  firstVisit: boolean;
  introDone: boolean;
  hasFont?: boolean;
}

const initialStoreState = {
  shopifyClient: client,
  isAdding: false,
  cartIsOpen: false,
  page: undefined,
  customerEmail: undefined,
  customerName: undefined,
  customerToken: undefined,
  lang: 'en',
  orders: [],
  navIsOpen: false,
  collectionMenus: [],
  collectionGrid: 'm',
  collectionGridSize: '',
  setCollectionGridSize: () => null,
  firstVisit: true,
  introDone: false,
  hasFont: false,
  checkout: {
    lineItems: [],
  } as Checkout,
};

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
});

const createNewCheckout = (store: InitialStore): Checkout => {
  return store.shopifyClient.checkout.create();
};

const fetchCheckout = (store: InitialStore, id: string): Checkout => {
  return store.shopifyClient.checkout.fetch(id);
};

const setCheckoutInState = (checkout: Checkout, setStore: any) => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
  }

  setStore((prevState: InitialStore) => {
    return { ...prevState, checkout };
  });
};

const initCustomer = (setStore: any) => {
  const customerEmail = cookie.get('customer_email');
  const customerToken = cookie.get('customer_token');
  const customerName = cookie.get('customer_firstName');

  if (customerEmail && customerToken && customerName) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerEmail, customerToken, customerName };
    });
  }
};

const StoreContextProvider = ({ children }: { children: any }) => {
  const [store, setStore] = useState(initialStoreState);
  const [initStore, setInitStore] = useState(false);
  const [collectionGridSize, setCollectionGridSize] = useState(null); // ['s', 'm', 'l'
  const [menus, setMenus] = useState([]);
  const [introDone, setIntroDone] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      await fetch(`https://taigatakahashi.myshopify.com/collections.json`)
        .then((response) => response.json()) // parse JSON from request
        .then((resultData) => {
          setMenus(resultData.collections);
        });
    };

    fetchCollections();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIntroDone(true);
    }, 1000);
  }, []);

  useEffect(() => {
    setCollectionGridSize(localStorage.getItem('collectionGridSize') || 'm');
  }, []);

  useEffect(() => {
    localStorage.setItem('collectionGridSize', collectionGridSize);
  }, [collectionGridSize]);

  useEffect(() => {
    if (initStore === false) {
      const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== 'undefined';
        const existingCheckoutId = isBrowser
          ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
          : null;

        if (existingCheckoutId) {
          try {
            const checkout = await fetchCheckout(store, existingCheckoutId);

            // Make sure none of the items in this cart have been deleted from Shopify.
            if (checkout.lineItems.some((lineItem) => !lineItem.variant)) {
              throw new Error(
                'Invalid line item in checkout. This variant was probably deleted from Shopify'
              );
            }

            // Make sure this cart hasn’t already been purchased.
            if (!checkout.completedAt) {
              setCheckoutInState(checkout, setStore);
              return;
            }
          } catch (e) {
            localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, '');
          }
        }

        const newCheckout = await createNewCheckout(store);
        setCheckoutInState(newCheckout, setStore);
      };

      initCustomer(setStore);
      initializeCheckout();
      setInitStore(true);
    }
  }, [store, setStore, store.shopifyClient.checkout, initStore, store.lang]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        menus,
        introDone,
        collectionGridSize,
        setCollectionGridSize: (size: string) => setCollectionGridSize(size),
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const { store } = useContext(StoreContext);
  return store;
}

function useCartCount() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  let count = 0;
  if (checkout.lineItems) {
    count = checkout.lineItems.reduce(
      (runningTotal: number, item: any) => item.quantity + runningTotal,
      0
    );
  }

  return count;
}

const setCustomerInState = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  async function updateCustomerInState() {
    const customerEmail = cookie.get('customer_email');
    const customerToken = cookie.get('customer_token');
    const customerName = cookie.get('customer_firstName');
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerEmail, customerToken, customerName };
    });
  }

  return updateCustomerInState;
};

function useCurrencyFormat(locale: any, currency: any, number: any) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  })
    .format(number)
    .replace('JPY', '')
    .trim();
}

function useCartTotals() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const tax = checkout.totalTaxV2
    ? `¥${useCurrencyFormat('ja-JP', 'JPY', Number(checkout.totalTaxV2.amount).toFixed(2))}`
    : '-';
  const total = checkout.totalPriceV2
    ? `¥${useCurrencyFormat('ja-JP', 'JPY', Number(checkout.totalPriceV2.amount).toFixed(2))}`
    : '-';

  return {
    tax,
    total,
  };
}

function useCartItems() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout.lineItems;
}

function useCustomer() {
  const {
    store: { customerEmail, customerName, customerToken },
  } = useContext(StoreContext);

  return { customerEmail, customerName, customerToken };
}

function useAddItemToCart() {
  // @ts-ignore
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);

  async function addItemToCart(variantId: string, quantity: number, attributes?: []) {
    if (variantId === '' || !quantity) {
      console.error('Both a size and quantity are required.');
      return;
    }

    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;
    const lineItemsToAdd = [{ variantId, quantity, customAttributes: attributes }];

    const newCheckout = await shopifyClient.checkout.addLineItems(checkoutId, lineItemsToAdd);

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        cartIsOpen: true,
        isAdding: false,
      };
    });
  }

  return addItemToCart;
}

function useRemoveItemFromCart() {
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId: any) {
    const newCheckout = await shopifyClient.checkout.removeLineItems(checkout.id, [itemId]);

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return removeItemFromCart;
}

function useUpdateItemsFromCart() {
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function updateItemsFromCart(items: any) {
    items = [].concat(items);
    const newCheckout = await shopifyClient.checkout.updateLineItems(checkout.id, items);

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return updateItemsFromCart;
}

function useCheckout() {
  const {
    store: { checkout },
  }: {
    store: InitialStore;
  } = useContext(StoreContext);

  return () => {
    window.open(checkout.webUrl);
  };
}

function useSetPage() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);
  async function setPage(page: string) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, page };
    });
  }
  return setPage;
}

function useSetPrevPage() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);
  async function setPrevPage(prevPage: string) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, prevPage };
    });
  }
  return setPrevPage;
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleCart() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, cartIsOpen: !cartIsOpen };
    });
  }

  return toggleCart;
}

function useSetLangage() {
  const {
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setLanguage(language: string) {
    if (language) {
      localStorage.setItem('lang', language);
      setStore((prevState: InitialStore) => {
        return { ...prevState, lang: language };
      });
    }
  }
  return setLanguage;
}

function useSetHasFont() {
  const {
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setHasFont(hf: boolean) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, hasFont: hf };
    });
  }
  return setHasFont;
}

function useSetFirstVisit() {
  const {
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setFirstVisit(fv: boolean) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, firstVisit: fv };
    });
  }
  return setFirstVisit;
}

function useSetIntroDone() {
  const {
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function setIntroDone(id: boolean) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, introDone: id };
    });
  }
  return setIntroDone;
}

function useSetCollectionGrid() {
  const {
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);


  async function setCollectionGrid(grid: string) {
    // window.scrollTo(0, 0);
    // ("set collection grid");
    setStore((prevState: InitialStore) => {
      return { ...prevState, collectionGrid: grid };
    });
  }
  return setCollectionGrid;
}

export default StoreContext;

export {
  client,
  StoreContextProvider,
  setCustomerInState,
  useAddItemToCart,
  useStore,
  useSetFirstVisit,
  useCustomer,
  useCartCount,
  useCartItems,
  useCartTotals,
  useCurrencyFormat,
  useSetPage,
  useSetPrevPage,
  useRemoveItemFromCart,
  useUpdateItemsFromCart,
  useCheckout,
  useToggleCart,
  useSetCollectionGrid,
  useSetLangage,
  useSetIntroDone,
  useSetHasFont,
};
