import { graphql, useStaticQuery, Link } from 'gatsby';
import { LocaleString } from 'src/components/global/localeString';

export const Footer = (props: any) => {
  const { container, location } = props;
  const data = useStaticQuery(graphql`
    query {
      allSanityMenus(filter: { title: { eq: "Footer" } }) {
        edges {
          node {
            _rawItems(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  `);

  const isPadded =
    location?.pathname !== '/philosophy/' && !location?.pathname?.includes('/collections/');
  const menu = data.allSanityMenus.edges[0].node._rawItems;

  return (
    <footer
      className={`relative z-0 ${
        container !== 'menu' ? ' bg-primary relative z-10 mb-mobile md:mb-0' : ''
      }     ${isPadded && 'pt-contentOffsetDesktopHalf'}`}
    >
      <ol className="px-desktop md:whitespace-nowrap md:overflow-x-scroll flex flex-col md:flex-row md:gap-desktop md:h-12">
        {menu?.map((item: any, index: number) => (
          <li
            key={item._key}
            className={`md:inline-block ml-0 ${index === 0 && 'md:ml-auto'} ${
              index === menu.length - 1 && 'md:mr-auto'
            }`}
          >
            {item._type === 'internalLink' ? (
              <>
                {item?.link?.content?.main?.slug && (
                  <Link title={`${item.title}`} to={`/${item.link.content.main.slug.current}`}>
                    <LocaleString en={item.title} jp={item.title_jp} />
                  </Link>
                )}
              </>
            ) : (
              <>
                {item.link && (
                  <a href={item.link} title={item.title} target="_blank" rel="noreferrer">
                    {item.title}
                  </a>
                )}
              </>
            )}
          </li>
        ))}
      </ol>
    </footer>
  );
};
