/* eslint-env es6 */
/* eslint-disable */
// import Helmet from 'react-helmet';
import { Footer } from 'src/components/footer';

const Layout = ({ children, location, title }: any) => {
  return (
    <main id="Inventory" className="bg-primary flex justify-between flex-col min-h-screen">
      {/* <Helmet title="Taiga Takahashi"></Helmet> */}
      {children}
      {!/products/.test(location.pathname) && <Footer location={location} />}
      {/* Dummy texts to force load condensed font */}
      <div className=" fixed transform -translate-x-full pointer-events-none top-0 left-0">
      <div className="font-condensed">&copy; 2023</div>
      <div className="font-extended">Taiga Takahashi</div>
      </div>
    </main>
  );
};

export default Layout;
