exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-artwork-js": () => import("./../../../src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-philosophy-biography-js": () => import("./../../../src/pages/philosophy/biography.js" /* webpackChunkName: "component---src-pages-philosophy-biography-js" */),
  "component---src-pages-philosophy-js": () => import("./../../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-philosophy-texts-js": () => import("./../../../src/pages/philosophy/texts.js" /* webpackChunkName: "component---src-pages-philosophy-texts-js" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-collections-jsx": () => import("./../../../src/templates/collections.jsx" /* webpackChunkName: "component---src-templates-collections-jsx" */),
  "component---src-templates-journal-post-jsx": () => import("./../../../src/templates/journalPost.jsx" /* webpackChunkName: "component---src-templates-journal-post-jsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

