/*eslint-env es6*/
/* eslint-disable */

import './src/styles/global.css';
import React from 'react';
import { StoreContextProvider } from 'src/context/siteContext';
import Layout from 'src/components/layout';

export const wrapRootElement = ({ element }) => {
  return <StoreContextProvider>{element}</StoreContextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// export const shouldUpdateScroll = () => {
//   return false;
// };
